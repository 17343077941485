// Global
import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'next/navigation';
import { PageController } from '@sitecore-search/react';

// Lib
import setStoreEntity from 'lib/utils/search/setStoreEntity';
import setUserGroups from 'lib/utils/search/setUserGroups';
import setUserGoals from 'lib/utils/search/setUserGoals';
import setGroupStoreId from 'lib/utils/search/setGroupStoreId';

// Local
import useDictionary from 'src/hooks/useDictionary';
import { useOcSelector } from 'src/redux/ocStore';

const SitecoreSetAllDiscoverRulesCover = () => {
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const loading = useOcSelector((s) => s?.ocUser?.loading);
  const loyaltyAccepted = useOcSelector((s) => s.ocUser?.user?.xp?.LoyaltyAccepted);
  const stateSelectedStoreId = useOcSelector((s) => s.storeReducer?.selectedStore?.storeId);
  // todo: remove the "any" type when "pets" is added in the types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const userPets = useOcSelector((s: any) => s.ocUser?.user?.xp?.Pets);
  const [selectedStoreId, setSelectedStoreId] = useState<string>('');
  const prevSelectedStoreIdRef = useRef<string>('');
  const prevSelectedUserGroups = useRef<Array<string>>([]);
  const searchParams = useSearchParams();

  const { getDictionaryValue } = useDictionary();
  const loyaltyAcceptedValue = getDictionaryValue('LTAC');

  useEffect(() => {
    if (stateSelectedStoreId !== selectedStoreId) {
      setSelectedStoreId(stateSelectedStoreId);
    }

    if (selectedStoreId && selectedStoreId !== prevSelectedStoreIdRef.current) {
      setStoreEntity(selectedStoreId);
      prevSelectedStoreIdRef.current = selectedStoreId;
    }
    // HCS-31413
    if (process.env.NEXT_PUBLIC_ENABLE_BOPIS == 'true') {
      setGroupStoreId(selectedStoreId, isAnonymous); // Enables BOPIS flag in Discover Payload
    }

    if (!loading && !isAnonymous) {
      const userGroupRules = [];

      // Terms and Conditions
      if (loyaltyAccepted) {
        userGroupRules.push(loyaltyAcceptedValue);
      }

      // Pets
      if (userPets) {
        for (const petKey in userPets) {
          if (userPets.hasOwnProperty(petKey)) {
            const petKeyItem = getDictionaryValue(petKey);
            userGroupRules.push(petKeyItem);
          }
        }
      }

      const userGroupRulesChanged =
        JSON.stringify(userGroupRules) !== JSON.stringify(prevSelectedUserGroups.current);

      if (userGroupRulesChanged) {
        setUserGroups(userGroupRules);
        prevSelectedUserGroups.current = userGroupRules;
      }
    }

    // Goal personalization
    const goals = searchParams.get('sc_trk');
    if (!loading) {
      if (goals) {
        const userGoalRules: Array<string> = [];

        // todo: replace the value with an array when the issue is fixed
        // when use an array instead of a single value the ordercloud shows 400 error
        // message: json: cannot unmarshal array into Go struct field ContextUser.Custom of type string

        userGoalRules.push(goals);
        setUserGoals(userGoalRules);
      } else {
        // set and empty object if there are no goals
        PageController.getContext().setUserCustom({});
      }
    }
  }, [
    isAnonymous,
    loading,
    loyaltyAccepted,
    stateSelectedStoreId,
    selectedStoreId,
    searchParams,
    loyaltyAcceptedValue,
    userPets,
    getDictionaryValue,
  ]);

  return null;
};

export default SitecoreSetAllDiscoverRulesCover;
